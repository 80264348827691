import {
  Button,
  Flex,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import React, {
  useCallback,
} from 'react';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import useRequest from 'shared/src/hooks/useRequest';
import { Field } from 'web-react-ui/src/reactFinalForm';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import PageHeader from 'web-react-ui/src/chakra/page/PageHeader';
import client from '../../../../services/client';
import businessModule from '../../../../modules/businesses';
import useProperty from '../../../useProperty';
import getPostPageTitle from '../components/getPostPageTitle';
import SectionHeader from '../components/SectionHeader';
import Post from '../interfaces/Post.interface';
import Content from './ContentSection';
import Preview from './PreviewSection';
import TextAreaField from './TextAreaField';


const fetchPost = async ({ businessId, postId }: { businessId: string, postId: string }): Promise<Post> => {
  return client
    .businesses.for(businessId)
    .social.posts.for(postId)
    .details();
};

const PostEdit = () => {
  const params = useParams<{ postId: string }>();
  const property = useProperty();
  const business = useSelector((state: RootStateOrAny) => businessModule.selectors.business.getData(state));
  const postRequest = useRequest(
    fetchPost,
    { propertyId: property.id, businessId: business.id, postId: params.postId },
  );
  const history = useHistory();
  const post = postRequest.result;

  const save = useCallback(
    async (newPost) => {
      // await new Promise(res => setTimeout(res, 500));
      await client
        .businesses.for(business.id)
        .social.posts.for(params.postId)
        .update({
          message: newPost.output.message,
        });
      history.replace(`../${params.postId}`);
    },
    [history],
  );

  const pageTitle = getPostPageTitle(post);

  return (
    <Flex gap="3em" direction="column">
      <PageBackButton to={`../${params.postId}`}>Back</PageBackButton>

      <SimpleForm initialValues={post} onSubmit={save}>
        <Flex direction="column" gap="3em">
          <PageHeader controls={<Button colorScheme="blue" type="submit">Save</Button>}>{pageTitle}</PageHeader>

          <Flex wrap="wrap" gap="3em">
            <Flex direction="column" gap="3em" flex="1" minW="40ch" alignItems="start">
              <Table variant="unstyled" w="auto">
                <Tbody>
                  <Tr>
                    <Td pt={0} pl={0}>
                      Schedule Date
                    </Td>
                    <Td pt={0}>
                      <SkeletonText isLoaded={!!post} noOfLines={1} minW="6em">
                        <Text>{post?.scheduledDate}</Text>
                      </SkeletonText>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>

              <SectionHeader>Content</SectionHeader>
              <Content post={post} />

              <SectionHeader>Post Content</SectionHeader>
              <TextAreaField name="output.message" />
            </Flex>

            {
              post && (
                <Field name="output.message">
                  {({ input }: { input: any }) => {
                    const newPost = {
                      ...post,
                      output: {
                        ...post.output,
                        message: input.value,
                      },
                    };

                    return (
                      <Preview post={newPost} />
                    );
                  }}
                </Field>
              )
            }
          </Flex>
        </Flex>
      </SimpleForm>
    </Flex>
  );
};

export default PostEdit;
