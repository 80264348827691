import React from 'react';
import {
  Box,
  AbsoluteCenter,
  Button,
} from '@chakra-ui/react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import useRequest from 'shared/src/hooks/useRequest';
import Loader from 'web-react-ui/src/components/elements/Loader';
import { usePlanState } from '../../../hooks/usePlan';
import businessesModule from '../../../modules/businesses';
import { fetchProfile } from '../../../modules/social/socialActions';
import client from '../../../services/client';
import ErrorView from '../PlansView2/ErrorView';
import PostDetails from './details/PostDetails';
import PostEdit from './details/PostEdit';
import NewPostView from './new/NewPostView';
import SocialSettings from './settings/SocialSettings';
import SocialSignupPostrollModal from './signup/postrollSlideshow/SocialSignupPostrollModal';
import SocialViewNotEnabled from './signup/SocialViewNotEnabled';

import SocialViewNotSignedUp from './signup/SocialViewNotSignedUp';
import PostListView from './SocialPostsView';

const SocialView = ({ isOwner }: { isOwner: boolean }) => {
  const { plan } = usePlanState();
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const dispatch = useDispatch();
  const isSocialAiEnabled = plan?.settings?.social_ai__enabled;

  const profileRequest = useRequest(
    async () => {
      if (!plan.settings) return null;

      if (!isSocialAiEnabled) {
        dispatch(fetchProfile({}));
        return {};
      }

      const hasPermission = await client.access.checkPermissions(
        { businessId: business.id },
        ['business.socialAi.profile.get'],
      );

      if (!hasPermission) {
        dispatch(fetchProfile({
          missingPermissions: true,
        }));
        return {};
      }

      const profile = await client
        .businesses.for(business.id)
        .social.getProfile()
        .catch((err) => {
          if (err.status === 404) return {};
          throw err;
        });
      dispatch(fetchProfile(profile));
      return profile;
    },
    [isSocialAiEnabled, plan.settings],
  );

  if (!plan?.settings || !profileRequest.settled) {
    return (
      <Box minHeight="100vh">
        <AbsoluteCenter>
          <Loader active />
        </AbsoluteCenter>
      </Box>
    );
  }

  if (profileRequest.error) {
    return (
      <ErrorView
        title="Profile Error"
        error={profileRequest.error}
        message="There was an error loading your profile."
      />
    );
  }

  if (!isSocialAiEnabled) return <SocialViewNotEnabled />;

  const isConnected = !profileRequest.loading && profileRequest.result?.enabled;
  if (!isConnected) return <SocialViewNotSignedUp />;

  return (
    <Switch>
      <Route path="/business/:businessId/social/settings">
        <SocialSettings refreshProfile={profileRequest.run} />
      </Route>
      <Route path="/business/:businessId/social/new">
        {
          isOwner
            ? (
              <NewPostView />
            )
            : (
              <Redirect to="../social" />
            )
        }
      </Route>
      <Route path="/business/:businessId/social/:postId/edit">
        <PostEdit />
      </Route>
      <Route path="/business/:businessId/social/:postId">
        <PostDetails />
      </Route>
      <Route>
        <PostListView />
      </Route>
    </Switch>
  );
};


const SocialViewLandingWrapper = ({ isOwner }: { isOwner: boolean }) => {
  return (
    <>
      <SocialSignupPostrollModal />
      <SocialView isOwner={isOwner} />
    </>
  );
};

export default SocialViewLandingWrapper;
