import React, { ReactNode } from 'react';
import {
  Box,
  Center,
  Spinner as ChakraSpinner,
} from '@chakra-ui/react';

const Spinner = ({ children, loading, ...rest }: { children: ReactNode, loading: boolean }): ReactNode => {
  if (!children) {
    if (!loading) return null;
    return <ChakraSpinner {...rest} />;
  }

  if (loading) {
    return (
      <Box position="relative">
        <Center minWidth={0} position="absolute" w="100%" h="100%">
          <ChakraSpinner {...rest} />
        </Center>
        <Box opacity={0.5}>
          {children}
        </Box>
      </Box>
    );
  }

  return children;
};

export default Spinner;
