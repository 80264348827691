import { Heading } from '@chakra-ui/react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import { COUNTRIES } from 'shared/src/constants/countries';
import {
  formatSocial,
  parseSocial,
  SOCIAL_PROFILE_FIELDS
} from 'shared/src/constants/socialProfiles';

import usePromise from 'shared/src/hooks/usePromise';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import StateCodeFields from 'web-react-ui/src/components/businessSignUp/StateCodeFields';
import Menu from 'web-react-ui/src/components/collections/Menu';
import CancelButton from 'web-react-ui/src/components/elements/CancelButton';
import View from 'web-react-ui/src/components/layout/View';
import Map from 'web-react-ui/src/map/Map';
import { TextField } from 'web-react-ui/src/reactFinalForm/fields';
import FieldListField from 'web-react-ui/src/reactFinalForm/fields/FieldListField';
import DefaultLocaleMessage from 'web-react-ui/src/reactFinalForm/fields/localizableField/DefaultLocaleMessage';
import LocaleSelector from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocaleSelector';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import LocalizableTextField from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableTextField';
import SelectField from 'web-react-ui/src/reactFinalForm/fields/SelectField';
import FormError from 'web-react-ui/src/reactFinalForm/FormError';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import {
  atLeastOneCompleteLocalization,
  forAllLocales,
  max,
  phoneNumber,
  required,
  validateUrl
} from 'web-react-ui/src/reactFinalForm/validators';
import { strings } from '../../i18n';
import client from '../../services/client';
import PlatformTranslationWrapper from '../i18n/PlatformTranslationWrapper';

import useProperty from '../useProperty';

const compareLocationChanges = changes => (indexLocation) => {
  if (indexLocation.name !== changes.name) return false;
  if ((indexLocation.url || '') !== (changes.url || '')) return false;
  if (indexLocation.phone !== changes.phone) return false;
  if (indexLocation.address.streetAddress !== changes.address.streetAddress) return false;
  if (indexLocation.address.street !== changes.address.street) return false;
  if (indexLocation.address.suite !== changes.address.suite) return false;
  if (indexLocation.address.state !== changes.address.state) return false;
  if (indexLocation.address.city !== changes.address.city) return false;
  if (indexLocation.address.postalcode !== changes.address.postalcode) return false;
  if (indexLocation.address.country !== changes.address.country) return false;
  if (indexLocation.address.centre[0] !== changes.address.centre.long) return false;
  if (indexLocation.address.centre[1] !== changes.address.centre.lat) return false;

  return true;
};

const EditLocationView = ({ businessId, locationId, history }) => {
  const property = useProperty();
  const [originalLocation, setOriginalLocation] = useState(null);
  const [getLocation, isLoading, location] = usePromise(
    () => client.businesses.for(businessId).locations.for(locationId).editWithLocalization(),
    [businessId, locationId]
  );

  const cancel = useCallback(() => {
    history.push(`/business/${businessId}/locations/${locationId}`);
  }, [history, businessId, locationId]);

  const [updateLocation, isUpdating] = usePromise(
    async (updates) => {
      await client
        .businesses.for(businessId)
        .locations.for(locationId)
        .update(originalLocation, updates);

      // Wait for search index to be updated...
      const compareFn = compareLocationChanges(updates);
      await client
        .properties.for(property.id)
        .businesses.for(businessId)
        .locations.for(locationId)
        .waitForIndex(
          compareFn,
          () => {
            throw new Error(strings('dashboard.screen.editLocationView.locationUpdated'));
          }
        );

      cancel();
    },
    [property, businessId, locationId, location, originalLocation]
  );

  const onCenterUpdate = useCallback((updatedMarker) => {
    location.address.centre.lat = updatedMarker.latlng.lat;
    location.address.centre.long = updatedMarker.latlng.lng;
  });

  useEffect(
    () => {
      getLocation()
        .then((loc) => {
          setOriginalLocation(_.cloneDeep(loc));
          return loc;
        });
    },
    [getLocation]
  );

  return (
    <PlatformTranslationWrapper>
      <BigModal open={true}>
        <LocalizableSimpleForm
          onSubmit={updateLocation}
          initialValues={location}
          loading={isLoading || isUpdating}
          validateLocale={atLeastOneCompleteLocalization(['nameLocalized'])}
        >
          <BigModal.Contents>
            <BigModal.Header className='p1'>
              <Menu secondary>
                <Menu.Item fitted>
                  <CancelButton type='button' onClick={cancel} />
                </Menu.Item>
                <Menu.Menu position='right'>
                  <Menu.Item fitted>
                    <FormSubmit
                      label={strings('dashboard.screen.editLocationView.field.label.save')}
                      ignoreValidity
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
            </BigModal.Header>
            <BigModal.Body>

              <View>
                <View.Section narrow>
                  <div className='flex fdr jcc aic'>
                    <h2>{strings('dashboard.screen.editLocationView.editLocation')}</h2>
                    <LocaleSelector inline className='mla' />
                  </div>
                  <DefaultLocaleMessage localeInfoKey='_localization' />
                  <FormError />
                  <LocalizableTextField
                    wrapLabel
                    label={strings('dashboard.screen.editLocationView.field.label.name')}
                    name='nameLocalized'
                    placeholder={strings('dashboard.screen.editLocationView.field.label.name')}
                    validate={forAllLocales(max(64))}
                  />
                  <TextField
                    placeholder='604-555-1212'
                    wrapLabel
                    name='phone'
                    label={strings('dashboard.screen.editLocationView.field.label.number')}
                    optional
                    validate={phoneNumber}
                    info={strings('dashboard.screen.editLocationView.field.placeholder.number')}
                  />
                  <TextField
                    wrapLabel
                    name='url'
                    label={strings('ui.field.label.website')}
                    optional
                    validate={validateUrl}
                    info={strings('ui.field.url.info')}
                    parse={v => v}
                  />
                </View.Section>

                <View.Section narrow>
                  <div className='flex'>
                    <div className='mr1 fg1'>
                      <TextField
                        placeholder='1234'
                        validate={required}
                        wrapLabel
                        name='address.streetAddress'
                        label={strings('dashboard.screen.editLocationView.field.label.streetNumber')}
                      />
                    </div>
                    <div className='fg2'>
                      <TextField
                        placeholder='Oak St.'
                        validate={required}
                        wrapLabel
                        name='address.street'
                        label={strings('dashboard.screen.editLocationView.field.label.streetName')}
                      />
                    </div>
                  </div>
                  <div className='flex mt1'>
                    <div className='mr1 fg1'>
                      <TextField
                        wrapLabel
                        name='address.suite'
                        optional
                        label={strings('dashboard.screen.editLocationView.field.label.suite')}
                      />
                    </div>
                    <div className='fg3'>
                      <TextField
                        placeholder='Toronto'
                        validate={required}
                        wrapLabel
                        name='address.city'
                        label={strings('dashboard.screen.editLocationView.field.label.city')}
                      />
                    </div>
                  </div>
                  <div className='flex mt1'>
                    <StateCodeFields name='address' />
                  </div>
                  <div className='mt1'>
                    <SelectField
                      validate={required}
                      search
                      wrapLabel
                      name='address.country'
                      label={strings('ui.field.label.country')}
                      autoSelectFirst
                      fluid
                      options={COUNTRIES}
                    />
                  </div>
                </View.Section>
                {location && (
                  <View.Section narrow>
                    <Map
                      markers={[{ center: location.address.centre.serialize(), draggable: true, isEnabled: true }]}
                      onMarkerUpdate={onCenterUpdate}
                      initialFitToMarkers={true}
                      fitToMarkers={false}
                      initialZoom={15}
                    />
                  </View.Section>
                )}
                <View.Section narrow>
                  <Heading size="md" mb={2}>Social Media Profiles</Heading>
                  <FieldListField
                    name="socialProfiles"
                    addButtonLabel="Add"
                    fields={SOCIAL_PROFILE_FIELDS}
                    parse={parseSocial}
                    format={formatSocial}
                  />
                </View.Section>
              </View>
            </BigModal.Body>
          </BigModal.Contents>
        </LocalizableSimpleForm>
      </BigModal>
    </PlatformTranslationWrapper>
  );
};

EditLocationView.propTypes = {
  businessId: PropTypes.string,
  locationId: PropTypes.string,
  history: PropTypes.object
};

export default EditLocationView;
