import React from 'react';

import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';

import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import PageLayout from 'web-react-ui/src/chakra/page/PageLayout';

import { strings } from '../../../../i18n';
import Accounts from './Accounts';
import Campaigns from './Campaigns';

const SocialSettings = () => {
  return (
    <PageLayout>
      <PageBackButton to="../social">{strings('ui.label.cancel')}</PageBackButton>
      <ViewHeader title="Social AI Settings" />

      <Accounts />

      <Campaigns />
    </PageLayout>
  );
};

export default SocialSettings;
