import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
  Link,
} from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Profile from './interfaces/Profile.interface';

const ProfileHealth = ({ profile }: { profile: Profile }) => {
  if (!profile?.health) return null;
  const unhealthyEntries = Object.entries(profile.health).filter(([type, health]) => !health.healthy);

  if (!unhealthyEntries.length) return null;
  return (
    <Alert status="error" fontSize="lg">
      <Flex>
        <AlertIcon />
        <Flex direction="column" gap="0.5em">
          <AlertTitle>Post Errors</AlertTitle>
          {unhealthyEntries.map(([type, health]) => (
            <AlertDescription key={type}>
              <Link as={RouterLink}
                    to={`./social/${health.lastPostId}`}
                    sx={{ textDecoration: 'underline' }}>
                Error with last {deTokenize(type)}
              </Link>
            </AlertDescription>
          ))}
        </Flex>
      </Flex>
    </Alert>
  );
};

const deTokenize = (token: string) => token
  .split('-')
  .map((str: string) => str[0].toUpperCase() + str.slice(1))
  .join(' ');

export default ProfileHealth;
