import React from 'react';
import { Textarea } from '@chakra-ui/react';
import { useField } from 'react-final-form';

const TextareaField = ({ name }: { name: string }) => {
  const { input } = useField(name, {});
  return <Textarea {...input} />;
};


export default TextareaField;
