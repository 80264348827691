import {
  Flex,
  Heading,
  Spacer,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

const PageHeader = ({
  children,
  controls,
  textTransform,
  ...rest
}: {
  children: ReactNode,
  controls?: ReactNode,
  textTransform?: string
}) => {
  return (
    <Flex>
      {/* @ts-ignore textTransform is totally valid on Heading */}
      <Heading textTransform={textTransform} {...rest}>{children}</Heading>
      <Spacer />
      {controls}
    </Flex>
  );
};

export default PageHeader;
