import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import useRequest from 'shared/src/hooks/useRequest';
import {
  Item,
  List,
} from 'web-react-ui/src/chakra/List';
import { Empty } from 'web-react-ui/src/chakra/TableList/TableList';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import businessesModule from '../../../../modules/businesses';
import client from '../../../../services/client';
import NetworkIcon from '../components/NetworkIcon';
import SectionHeader from '../components/SectionHeader';
import Profile from '../interfaces/Profile.interface';

const Accounts = () => {
  const profile = useSelector<{ social: { profile: Profile } }>(state => state.social.profile) as Profile;
  const items = profile.socialAccounts || [];

  const business = useSelector(state => businessesModule.selectors.business.getData(state));

  const manageAccounts = useRequest(async () => {
    const { url } = await client
      .businesses.for(business.id)
      .social.manageAccounts({ redirect: window.location.href });
    window.location.href = `${url}&logout=true`;
  });

  return (
    <Flex direction="column" gap="1em">
      <SectionHeader controls={
        <Button
          colorScheme="blue"
          variant="ghost"
          leftIcon={<AddIcon />}
          onClick={manageAccounts.run}
          isLoading={manageAccounts.loading}
        >
          Add
        </Button>
      }>
        Connected Accounts
      </SectionHeader>
      <WaitFor waitFor={!manageAccounts.loading} wrapContents>
        <List>
          <Empty isEmpty={!items.length}>
            <Text fontSize="xl">
              No connected social accounts.&nbsp;
              <Button fontSize="inherit" variant="link" onClick={manageAccounts.run} isLoading={manageAccounts.loading}>
                Add one
              </Button> to get started.
            </Text>
          </Empty>
          {items.map((account: { type: string }) => (
            <Item key={account.type} onClick={manageAccounts.run}>
              <Flex align="center" gap="1em">
                <NetworkIcon id={account.type} fontSize="1.5em" />
                <Text textTransform="capitalize">{account.type}</Text>
              </Flex>
            </Item>
          ))}
        </List>
      </WaitFor>
    </Flex>
  );
};

export default Accounts;
