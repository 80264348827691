import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import { COUNTRIES } from 'shared/src/constants/countries';

import SelectField from '../../reactFinalForm/fields/SelectField';
import TextField from '../../reactFinalForm/fields/TextField';
import { required, } from '../../reactFinalForm/validators';

import StateCodeFields from './StateCodeFields';

const Address = ({ name }) => {
  const { strings } = useI18Next();
  return (
    <>
      <Box flexGrow={1} mb={4}>
        <SelectField
          dataTestId='businessAddress-country'
          validate={required}
          wrapLabel
          placeholder={strings('ui.field.placeholder.chooseCountry')}
          name={`${name}.country`}
          fluid
          label={strings('ui.field.label.country')}
          options={[COUNTRIES[0]]} /* only Canada for now */
        />
      </Box>
      <Flex mb={4}>
        <Box flexGrow={1} mr={3}>
          <TextField
            placeholder='1234'
            validate={required}
            wrapLabel
            name={`${name}.streetAddress`}
            label={strings('ui.field.label.streetNumber')}
          />
        </Box>
        <Box flexGrow={2}>
          <TextField
            placeholder={strings('ui.field.placeholder.streetName')}
            validate={required}
            wrapLabel
            name={`${name}.street`}
            label={strings('ui.field.label.streetName')} />
        </Box>
      </Flex>
      <Flex mb={4}>
        <Box flexGrow={1} mr={3}>
          <TextField
            wrapLabel
            name={`${name}.suite`}
            optional
            label={strings('ui.field.label.suite')}
          />
        </Box>
          <Box flexGrow={3}>
          <TextField
            placeholder={strings('ui.field.placeholder.city')}
            validate={required}
            wrapLabel
            name={`${name}.city`}
            label={strings('ui.field.label.city')}
          />
        </Box>
      </Flex>
      <StateCodeFields name={name} />
    </>
  );
};

Address.propTypes = {
  name: PropTypes.string
};

export default Address;

